/* eslint-disable no-use-before-define */

import React, { useEffect } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import theme from '../theme.js';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { setProject as setProjectContext,  useAuthState, useAuthDispatch  } from '../Context' 

const useStyles = makeStyles((theme) => ({
  root: {
    
  },
  title: {
    flexGrow: 0,
    minWidth:120,
    fontSize: 21,
      '&::before':{
      border:'none'
    }
  }, 
   subtitle:{
    fontSize:14,
    fontWeight:700,
    paddingBottom:'0px',
    // transform:'none'
  },
  mission_container:{
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
    missionBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // paddingTop: theme.spacing(2),
    ...theme.mixins.toolbar,
  },
  grid:{
    // paddingLeft:theme.spacing(8),
    // paddingRight:theme.spacing(8),

  },
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    padding:theme.spacing(.75),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      padding:theme.spacing(.25),
    },
    boxShadow: '0px 12px 18px rgba(0, 0, 0, 0.24)'

  },
   search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade('#575865', 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    // marginTop:theme.spacing(2),
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    // width: '100%',
    [theme.breakpoints.up('sm')]: {
      // marginLeft: theme.spacing(3),
      width: 'auto',
    },
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)'
  },
  searchIcon: {
    right:0,
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    color:theme.palette.common.white,
    padding: theme.spacing(1.5, 1, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: theme.spacing(2),
    transition: theme.transitions.create('width'),
    width: '100%',
    // [theme.breakpoints.up('md')]: {
    //   width: '20ch',
    // },
  },
   lockIcon: {
    padding: theme.spacing(1, 1),
    margin:theme.spacing(0,1,1,1),
    // height: '100%',
    // position: 'absolute',
    // pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
 expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  rowActive:{
    opacity:1.0,
    outline: 'solid 1px',
    color:'white',
    transition:'opacity .25s'
  },
  missionTitle:{
    opacity:1.0,
    color:'#595A6B',
    transition:'opacity .25s',
    fontSize:'12px',
    // width:'95px'
  },
  percentage:{
    color:'#888DE0',
    fontStyle:'bold',
    fontWeight:700
  },
  blueText:{
    color:'#888DE0',
  },
  button:{
     [theme.breakpoints.down('sm')]: {
        padding:'0px'
     }
  },
  boxDrop:{
    width:"93%",
    padding: theme.spacing(0,3),
    [theme.breakpoints.down('md')]: {
      width:"80%",
      padding: theme.spacing(0,0),
    }
  },
   formControl:{
    '& label':{
         textTransform: 'uppercase'
    },
    '& .MuiInputLabel-shrink':{
        transform: 'translate(0, 1.5px)!important',
    },
    '& .MuiInputLabel-formControl':{

        transform : 'translate(0, 30px)'
    },
  }
}));






export default function TopToolbar({ handleTargetSelect, handleDetailOpen,handleLogout}) {
    const classes = useStyles(theme);
    const {  missionData,  projectData, currentProject } = useAuthState(); //read user details from context  
    const dispatch = useAuthDispatch(); // read dispatch method from context
    
    const [project, setProject] = React.useState( currentProject );
    
    const handleChange = (event) => {
      setProject(event.target.value);
    };

    useEffect(()=>{
      setProject('Philippines COVID-19 Vaccine Response');
    },[]);

    useEffect(()=>{
      setProjectContext(dispatch, project);
    },[project])

    return(
      <Grid container >
        <Grid item xs={12} md={8} lg={8}>
        <div className={classes.missionBar}>
          <Tooltip title="Mission Details">
            <IconButton className={classes.button} onClick={ handleDetailOpen}>
              <Avatar alt="Mission Avatar" src="/mission_avatar.png" className={classes.avatar} />
            </IconButton>
          </Tooltip>
          <Box className={classes.boxDrop}>
            <FormControl className={classes.formControl}>
              <InputLabel className={classes.subtitle} id="mission-select-label">Mission</InputLabel>
              <Select
                labelId="mission-select-label"
                id="mission-select"
                value={project}
                onChange={handleChange}
                className={classes.title}
              >
              {projectData && projectData.projects 
                && projectData.projects.map((v,i)=>
                  <MenuItem key={`${v.name}menu`} value={v.name}>{v.name}</MenuItem>
                )
              }
              </Select>
            </FormControl>
           { missionData &&  <Box display='flex' pl={1} pr={2}>
              <svg width="100%" height="100%" viewBox="0 0 773 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 12.0001L760 12.0001" stroke="#2F3039" strokeWidth="12" strokeLinecap="round"/>
                <path d="M15 12H385" stroke="#888DE0" strokeWidth="6" strokeLinecap="round"/>
                <path d="M6 12.0001L760 12.0001" stroke="url(#paint0_linear)" strokeWidth="6" strokeLinecap="round"/>
                <circle cx="573" cy="12" r="8" fill="url(#paint0_linear)"/>
                <circle cx="761" cy="12" r="8" fill="url(#paint0_linear)"/>
                <circle cx="9" cy="12" r="8" fill="url(#paint0_linear)"/>
                <circle cx="197" cy="12" r="8" fill="url(#paint0_linear)"/>
                <circle cx="385" cy="12" r="8" fill="url(#paint0_linear)"/>
                <defs>
                    <linearGradient id="paint0_linear" x1="0" y1="12" x2="773" y2="12" gradientUnits="userSpaceOnUse">
                    <stop offset={(parseFloat(missionData.mission.status) / 100.0)-0.05} stopColor="#888DE0"/>
                    <stop offset={(parseFloat(missionData.mission.status) / 100.0)+0.05}  stopColor="#888DE0" stopOpacity="0"/>
                    </linearGradient>
                </defs>
              </svg>
              <Box pl={2}>
                <Typography color="inherit" noWrap className={classes.percentage}>
                { missionData.mission &&  missionData.mission.status}
                </Typography>
              </Box>
            </Box>   }
            <Box display="flex" justifyContent="space-between"  pr={4}>
              { missionData && 
                missionData.mission.timeline.map((v,i)=>
                    <Typography key={`${v.title}num`} color="inherit" noWrap className={clsx(classes.missionTitle,classes.blueText)}>
                      {`${ i.toLocaleString('en-US', {minimumIntegerDigits: 2,useGrouping: false})}. ${v.title}`}
                    </Typography>
                  )
              }
            </Box>
          </Box>
          </div>
        </Grid>
       <Grid item xs={12} md={4} lg={4}>
          <Box display="flex" pt={2} >
            <Box display="flex-inline" flexGrow={1} >
              { missionData && missionData.type == "mission" && <div className={classes.search}>
                <Autocomplete
                  freeSolo
                  // disableClearable
                  options={ missionData.targets.map((option) => option['Full Name'])}
                  onChange={(event, newValue)=>{
                      if (typeof newValue === 'string') {
                        handleTargetSelect(newValue);
                      }
                    }
                  }
                  renderInput={(params) => 
                    (
                    <React.Fragment >
                      <InputBase 
                        ref={params.InputProps.ref}
                        {...params}
                        placeholder="SEARCH"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        inputProps={{...params.inputProps}}
                        />
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                    </React.Fragment>
                  )}
                />
              </div>}
            </Box>
            <Box display="flex-inline">
              <Tooltip title="Logout">
                <IconButton
                  aria-label="logout"
                  onClick={handleLogout}
                  className={classes.lockIcon}
                >
                  <EnhancedEncryptionIcon />
                </IconButton>
              </Tooltip>
            {/*</div>*/}
            </Box>
          </Box>
        </Grid>
      </Grid>
           );
}