import React, { forwardRef, useState, useRef, useEffect,useImperativeHandle } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList } from 'react-window';
import theme from '../../theme.js';
import TableGraph from '../TableGraph';
import Loading from './Loading';
import { spacing } from '@material-ui/system';
import AutoSizer from 'react-virtualized-auto-sizer';
import MissionTargetCard from '../MissionTargetCard';
import CollectIcon from './icn-collecting.png';
import CompareIcon from './icn-compare.png';
import SendingIcon from './icn-sending.png';

import { useAuthDispatch, logout, fetchMissionData, useAuthState } from '../../Context';



const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 0,
    fontSize: 21,
  }, 
  subtitle:{
    fontSize:14,
    fontWeight:700,
    paddingBottom:'0px'
  },
  fadeOut:{
    opacity:0,
    'willChange':'opacity',
    // transition:'opacity .25s'

  },
  fadeIn:{
    opacity:1,
    'willChange':'opacity',
    // transition:'opacity .25s'

  },
  column:{
  	height: 'calc(100vh - 190px)',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'block',
    overflow: 'auto',
    overflowX:"hidden",
    opacity:1,

    transition:'opacity .25s',
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow:'none',
    cursor: 'pointer',
    // special characters in data URIs have to be encoded, see the compiled CSS in the codepen below
    // (also do not wrap the lines, that's just for better uderstanding)
    borderImageSource: 'url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 30 30%22%3E%3Crect x=%220.5%22 y=%220.5%22 width=%2229%22 height=%2229%22 fill=%22transparent%22 stroke=%22%23575865%22 stroke-width=%221%22 %2F%3E%3C%2Fsvg%3E")',
    borderImageSlice: '50%', // slice the image to 4 parts through the middle
    borderImageWidth: '15px', // width/height of the corners (or 40px)
		scrollbarWidth: 'thin',
		scrollbarColor:  '#535193 #313864',
		'&::-webkit-scrollbar': {
		  width: '0.4em'
		},
		'&::-webkit-scrollbar-track': {
		  boxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
		  webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
		  background: '#383945'
		},
		'&::-webkit-scrollbar-thumb': {
		  backgroundColor: 'rgba(255,255,255,.5)',
		  borderRadius: '.25rem',
		  width:'.5rem'
		}
  },
   dot:{
    	display:'inline-block',
    	width:theme.spacing(1),
    	height:theme.spacing(1),
    	margin:theme.spacing(.125,.25,0,0),
    	background:'#888DE0',
    	borderRadius:theme.spacing(1)
    },
    graph:{
 			top:-theme.spacing(4),
 			// height:"200px",
 			// top:0,
 			// height:'auto'
 			// marginTop:theme.spacing(2),
 			// overflow:'hidden',
 			marginBottom:-theme.spacing(5)
 			// paddingBottom:theme.spacing(1),
 			// padding:theme.spacing(0,0,0,0)
    }, 
    table:{
    	// width:'auto'
    },
    list:{
	    width: `calc(100% - ${theme.spacing(1)})`,
	    height:'inherit',
	    position: 'relative',
	    overflow: 'auto',
	    maxHeight:'1080px',
	    margin:theme.spacing(0,2),
	    [theme.breakpoints.down('md')]: {
	       marginBottom:theme.spacing(8),
	    },
	    '&>div' : {

	      // marginBottom:theme.spacing(2),
	      // marginRight:theme.spacing(2),
	    },
	    scrollbarWidth: 'thin',
	    scrollbarColor:  '#535193 #313864',
	      '&::-webkit-scrollbar': {
	        width: '0.4em'
	      },
	      '&::-webkit-scrollbar-track': {
	        boxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
	        webkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.0)',
	        background: '#383945'
	      },
	      '&::-webkit-scrollbar-thumb': {
	        backgroundColor: 'rgba(255,255,255,.5)',
	        borderRadius: '.25rem',
	        width:'.5rem'
	      }
	  },
	  listRow:{
	    // width:'95%',
	    // margin:'auto',
      padding:theme.spacing(0,1)
     },  
     rowActive:{
    opacity:1.0,
    outline: 'solid 1px #888DE0',
    color:'white',
    fontColor:'white',
    transition:'opacity .25s'
  },
  rowInactive:{
    opacity:1.0,
    fontColor:'#595A6B',

    color:'#595A6B',
    transition:'opacity .25s'
  },
  primary:{
  	fontWeight:700
  },
  device_holder:{
  	position:'relative',
  	width:'100%',
  	height:'100%',
  	maxHeight:"45vh"
  },
  device:{
  	position:'relative',
  	width:"100%",
  	display:'block',
		height:'auto',
  },
  ad:{
  	position:'absolute',
  	// height:"75%",
  	width:"100%",
  // 	display:'block',
		// height:'auto',
		// left:'-100%',
  	// // left:'10%',
  marginBottom:'10%'
  	// width:'100%',
  	// transform:'scale(.76)'
		// marginLeft: 'auto', 
		// marginRight: 'auto'
	}
}));


const ColumnHolder = ({title, subtitle, children})=>{
	let classes = useStyles(theme);

	return(
			<Grid item xs={12} md={6} lg={4}>
				<div className={clsx(classes.paper, classes.column)}>
					<Box display="flex" flexDirection="column" height={'100%'}>
						<Box>
							<Typography className={classes.subtitle} color="textSecondary">
								<span className={classes.dot}/> {subtitle}
							</Typography>
							<Typography className={classes.title} >
								{title}
							</Typography>
            </Box>
            {children}
           </Box>
         </div>
      </Grid>
    )
}


export default function Campaign({active}){

	let classes = useStyles(theme);
  const { missionData, missionLoading, missionErrorMessage } = useAuthState(); //read user details from context  
	const [targetIndex, setTargetIndex] = useState(0);
  const [selectedAnswer, setAnswer] = useState('');
  const [filteredTargets, setFilteredTargets] = useState(null);
  const [selectedAd, setSelectedAd] = useState('');


  useEffect(()=>{
   if(missionData.targets) setFilteredTargets(missionData.targets.filter((v)=>{return selectedAnswer.length ?  v["Response"] == selectedAnswer : true } ))
    setTargetIndex(0);
  },[selectedAnswer]);

	const ListRowItem = ({name,city,response,selected,handleClick,style})=>{
	    const cardRef = useRef();

	    return(
	        <div ref={cardRef} style={style} >
	        <ListItem selected ={selected}  alignItems="center" classes={{ root:clsx(classes.listRow, selected ? "" :classes.rowInactive), selected : clsx(classes.listRow, classes.rowActive)}} button onClick={handleClick}>
	          <ListItemText classes={{primary:classes.primary}} style={{flex:1,fontWeight:700}} primary={name} />
	          <ListItemText classes={{primary:classes.primary}} style={{flex:1,fontWeight:700}}  primary={city} />
	         {response && <ListItemText classes={{primary:classes.primary}} style={{flex:1,fontWeight:700}}  primary={response} /> }
          </ListItem>
	        </div>
	  );
	}


	const renderRow = 
	  React.useCallback(({index,style}) => {
	    return filteredTargets && 
	    (<ListRowItem handleClick = {()=>{setTargetIndex(index);}} 
	                  // activeColumn={activeColumn}
	                  name={filteredTargets[index]['Full Name']} 
	                  city={filteredTargets[index]['City']} 
	                  key={`${filteredTargets[index]['Full Name']}Row${index}`} 
                    // response={filteredTargets[index]['Response']}
	                  selected={index === targetIndex} 
	                  style={style}/>);
	  },[targetIndex,missionData,filteredTargets]);
	// const renderTargetCard =
	//    React.useCallback(({index,style})=>{
	//     return data && Object.keys(data).length > 0 && 
	//     (<TargetDetailCard activeColumn={activeColumn} 
	//                        handleClick = {()=>{
	//                             setActiveColumn('card');
	//                             // handleTargetSelect(data.targets[index]['Full Name']); 
	//                             handleRowClick(index);}} 
	//                        key={`${data.targets[index]['Full Name']}Card${index}`} 
	//                        target={data.targets[index]} 
	//                        selected={index === targetIndex} 
	//                        style={style}/>);
	//   }, [data,targetIndex,handleTargetSelect,activeColumn]);
let innerSvg = `<path d="M1 37C1 17.1177 17.1177 1 37 1H363C382.882 1 399 17.1177 399 37V565H1V37Z" fill="#4B4D60" stroke="#888DE0"/>
							<path d="M17 42C17 28.7452 27.7452 18 41 18H358C371.255 18 382 28.7452 382 42V565H17V42Z" fill="#2E2F39"/>
							<path d="M180 40H235" stroke="#4B4D60" stroke-width="5" stroke-linecap="round"/>
							<circle cx="249" cy="40" r="3" fill="#4B4D60"/>
							<rect x="37" y="532" width="322" height="34" fill="#4B4D60"/>
							<rect x="37" y="60" width="322" height="37" fill="#4B4D60"/>
							<image xlink:href="/###" width="322" height="386" x="40" y="120" />
							`;

		return(
 			<Grid container spacing={3} className={active?classes.fadeIn : classes.fadeOut} >
				<ColumnHolder title="01. Audience" subtitle ="SCOUT" >
					{false ? 
						<Loading  icon={CollectIcon}  
											message="chatbots collections surveys..." 
											status={true} />
						: 
          (<React.Fragment>
	          <Box px={3}>
	            {missionData && missionData.mission &&  missionData.mission.questions && <TableGraph 
	             subtitle={missionData.mission.questions.question} 
	             data={missionData.mission.questions.answers} 
               graph="pie"
               clickHandler={setAnswer}
	             graphStyle={classes.graph}
	             tableStyle={classes.table}
	             />}
	          </Box>
	          <Box mb={2}>
	          {filteredTargets&& filteredTargets[targetIndex] && 
	          	<MissionTargetCard 
		           target={filteredTargets[targetIndex]} />}
		        </Box>
							<List className={classes.list} dense={true}>
							  <Box px={1} pr={6}>
							 {filteredTargets &&
									  filteredTargets.filter((v)=>{return selectedAnswer.length ?  v["Response"] == selectedAnswer : true } ).map((v,i)=>{
									    return renderRow({index:i})
									})}
							  </Box>
	        		</List>
        	 	</React.Fragment>)
        	}
			  </ColumnHolder>
				<ColumnHolder title="02. Demographics" subtitle ="ANALYZE" >
          {false ? 
          	<Loading  icon={CompareIcon}  
											message="comparing and finding other like minded individuals..." 
											status={false} />
          	:	
           (<Grid container style={{padding:theme.spacing(0,3)}} spacing={2}>
		        {missionData && missionData.mission && missionData.mission.demographics 
		        &&  <Grid xs={12} sm={12}lg={12} item><TableGraph 
		              title={"Consumer Interests"} 
		              data={missionData.mission.demographics.consumer_interests} graph="bar"/></Grid>}
		               
		        {missionData && missionData.mission && missionData.mission.demographics 
		        && <Grid xs={12} sm={12} lg={12} item><TableGraph 
		              title={"Device Usage"} 
		              graphStyle={classes.graph}
		              data={missionData.mission.demographics.device_usage} graph="stack"/></Grid>}
		        {missionData && missionData.mission && missionData.mission.demographics
		        && <Grid xs={12} sm={12} lg={12} item style={{ paddingBottom: '0px'}}><TableGraph 
		              title={"User Devices"} 
		              data={missionData.mission.demographics.user_devices} graph={"pie"}/></Grid>
		            }
		       </Grid>)
         }
			  </ColumnHolder>
				<ColumnHolder title="03. Narratives" subtitle ="MESSAGE" >
				 {false ? 
            <Loading  icon={SendingIcon}  
              message="preparing content for delivery..." 
              status={false} />
            :	
            (<React.Fragment>
               {missionData && missionData.mission &&  missionData.mission.narrative && <Box pt={1} className={classes.device_holder} position="inline" display="flex" px={2} my={2} mx={0}>
                <svg  dangerouslySetInnerHTML={{__html: innerSvg.replace("###", missionData.mission.narrative.find((v,i)=> {return selectedAd.length ? v.category == selectedAd : i==0;}).ad)}} 
                  width="100%" 
                  height="100%" 
                  viewBox="0 0 400 566" 
                  fill="none" 
                  xmlns="http://www.w3.org/2000/svg"/>
              </Box>}
              <Box px={3} flexGrow={1}>
              {missionData && missionData.mission && missionData.mission.narrative &&  
                <TableGraph 
                  title={"Narrative ST Rank"} 
                  clickHandler={setSelectedAd}
                  data={missionData.mission.narrative} 
                  graph="bar"/>
              }
              </Box>
            </React.Fragment>)
            }
        </ColumnHolder>
       </Grid>
	);
}