import React, { useEffect } from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import CardContainer from './CardContainer';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Slider from '@material-ui/core/Slider';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { red } from '@material-ui/core/colors';
import theme from '../theme.js';

// import SVG from 'react-inlinesvg';
import { setRegion as setRegionContext, setActor as setActorContext, useAuthState, useAuthDispatch  } from '../Context' 


const useStyles = makeStyles((theme) => ({
  title:{
    fontSize: 21,
    '&::before':{
        border:'none'
    },
    minWidth:120
  },
  subtitle:{
    fontSize:14,
    fontWeight:700,
    paddingBottom:'0px',
    // transform:'none'
  },


  media: {
    // height: 0,
    // borderLeft:'solid 1px #575865',
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    padding:theme.spacing(0),
    // width: `calc(100% + ${2*theme.spacing(3)}px)`, // twice the value of the parent's padding
    // marginLeft: - theme.spacing(3), // -1 * parent's padding
    pointerEvents:'none',
    zIndex:-10,
    color: 'linear-gradient(#e66465, #9198e5)',
    height:'100%',
    // marginLeft:theme.spacing(2),
    // marginRight:-theme.spacing(2),

  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
    width:28,
    height:28,
    flex: '0 0 auto',
    marginRight: '16px'
  },
  divider:{
    marginTop:'8px',
    marginBottom:'8px',
    // paddingLeft:'0px',
    // paddingRight:'0px'
  },
  cardHeader:{
    padding:'0px',
    // paddingTop:'2px',
    // paddingBottom:'2px',
    '& .MuiCardHeader-action': {
        margin:0,
        '& button':{
            padding: '8px'
        }
    },
    '& .MuiCardHeader-title':{
        fontSize: 21,
    },
    '&:last-child':{
          paddingBottom:'0px',
    }

  },
  menuItem:{
     display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // paddingTop: theme.spacing(2),
    ...theme.mixins.toolbar,
  },
  formControl:{
    '& label':{
         textTransform: 'uppercase'
    },
    '& .MuiInputLabel-shrink':{
        transform: 'translate(0, 1.5px)!important',
    },
    '& .MuiInputLabel-formControl':{
        transform : 'translate(0, 30px)'
    },
  }
}));

const CustomSlider = withStyles({
  root: {
    color: '#595A6B',
    height: 1,
    padding: '13px 0',
    // position:'relative',
    // transform:'translateX(-120%)'
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#2b2d32',
    border: '1px solid #595A6B',
    borderRadius:'0px',
    marginTop: -8,
    marginLeft: -8,
    // boxShadow: '#ebebeb 0 2px 2px',
    // '&:focus, &:hover, &$active': {
    //   boxShadow: '#ccc 0 2px 3px 1px',
    // },
    '& .bar': {
      // display: inline-block !important;
      height: 1,
      width: 1,
      backgroundColor: '#595A6B',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  track: {
    height: 1,
  },
  rail: {
    color: '#595A6B',
    opacity: 1,
    height: 1,
  },
})(Slider);

const Region = ()=>{
  const classes = useStyles(theme);
  const { currentRegion, currentActor, projectData, currentProject } = useAuthState(); //read user details from context  
  const dispatch = useAuthDispatch() // read dispatch method from context
  const [currentProjectData, setCurrentProjectData] = React.useState(null);

  const [region, setRegion] = React.useState(currentRegion);

  const [scale, setScale] = React.useState(1.0);
  const handleChange = (event) => {
      setRegion(event.target.value);
  };

  useEffect(()=>{
    if (projectData && currentProject){
      let pj = projectData.projects.find((v)=> v.name == currentProject);
      if (pj){
          setCurrentProjectData(pj)
      }
    }
  },[projectData, currentProject]);

  useEffect(()=>{

    if ( currentProjectData && currentProjectData.missions.length){
     setRegion(currentProjectData.missions[0].region.split(' ').join('_').toLowerCase());
    }
  },[currentProjectData])

  useEffect(()=>{
      setRegionContext(dispatch, region);
      setScale(1.0);
  },[region]);

  const handleSliderChange = (event, newValue) => {
      setScale(newValue);
    };

    return(
      <React.Fragment>
        <CardContent
         className={classes.cardHeader} >
          <FormControl className={classes.formControl}>
            <InputLabel id="region-select-label" >
              <Typography className={classes.subtitle} color="textSecondary">
                Region
              </Typography>
            </InputLabel>
            <Box className={classes.menuItem} pt={2}>
              {region && <Avatar aria-label="region" src={`/countries/InfluenceFlags-${region}.png`} className={classes.avatar}>
              </Avatar>}
              <Select
                labelId="region-select-label"
                id="region-select"
                value={region}
                onChange={handleChange}
                className={classes.title}
              >
                 {currentProjectData && currentProjectData.missions.map((v,i)=>
                    <MenuItem  key={`${v.region}menuItem`} value={v.region.split(' ').join('_').toLowerCase()} >{v.region}</MenuItem>
                 )}
                
              </Select>
            </Box> 
          </FormControl>
         </CardContent>
        {/*} <div style={{transform:`scale(${scale})`, fill:'blue'}}>
        <SVG
        className={classes.media}
          // baseURL="/home"
          cacheRequests={true}
          description="The React logo"
          // loader={<span>Loading...</span>}
          // onError={(error) => console.log(error.message)}
          // onLoad={(src, hasCache) => console.log(src, hasCache)}
          // preProcessor={(code) => code.replace(/fill=".*?"/g, 'fill="currentColor"')}
          preProcessor={(code)=> code.replace(/fill=".*?"/g, 'fill="url(#grad1)"').replace('</svg>', `<linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" style="stop-color:rgb(255,255,0);stop-opacity:1" />
      <stop offset="100%" style="stop-color:rgb(255,0,0);stop-opacity:1" />
    </linearGradient></svg>`)}
          src="/svgs/us.svg"
          title="React"
          // uniqueHash="a1f8d1"
          // uniquifyIDs={true}
        />
        </div>*/}
     {region && <Box py={2} display="block" style={{overflow:'hidden'}}><CardMedia
        component="img"
          className={classes.media}
          image={`/countries/InfluenceMaps-${region}.svg`}
          title={`${region} Map`}
          style={{transform:`scale(${scale})`}}
      /></Box>}
     {region && <CustomSlider
          defaultValue={1.5}
          onChange={handleSliderChange}
          min={0.5}
          max={2.5}
          step={.001}
           aria-label="Map Zoom Thumb"

          />}
</React.Fragment>
      )
}

const Actor = ()=>{
    const classes = useStyles(theme);
    const { currentRegion, currentActor, projectData, currentProject } = useAuthState();
    const dispatch = useAuthDispatch() 
    const [currentProjectData, setCurrentProjectData] = React.useState(null);

    const [actor, setActor] = React.useState(currentActor);

    const [actors, setActors] = React.useState(null);
     useEffect(()=>{
      if (projectData && currentProject){
        let pj = projectData.projects.find((v)=> v.name == currentProject);
        if (pj){
              setCurrentProjectData(pj)
        }
      }
    },[projectData, currentProject]);

    const handleChange = (event) => {
        setActor(event.target.value);
    };

    useEffect(()=>{
      // console.log(projectData && ;
      if(projectData){
        let missions = currentProjectData.missions.filter((v)=>v.region.split(' ').join('_').toLowerCase() == currentRegion);
        if(missions.length) {
          setActors(missions[0].actors);
          if(missions[0].actors.length){
            setActor(missions[0].actors[0].split(' ').join('_').toLowerCase());
          }
        }
      }
   },[currentRegion]);

    useEffect(()=>{
        setActorContext(dispatch, actor);
    },[actor]);

    return(
      <React.Fragment>
        <CardContent className={classes.cardHeader} >
          <FormControl className={classes.formControl}>
            <InputLabel id="actor-select-label" >
              <Typography className={classes.subtitle} color="textSecondary">
                Actor
              </Typography>
            </InputLabel>
            <Box className={classes.menuItem} pt={2}>
               {actor && <Avatar aria-label="region" src={`/countries/InfluenceFlags-${actor}.png`} className={classes.avatar}>
              </Avatar>}
                <Select
                  labelId="actor-select-label"
                  id="actor-select"
                  value={actor}
                  onChange={handleChange}
                  className={classes.title}
                >
                  { actors && actors.map((v,i)=>
                      <MenuItem key={`${v}menuItem`} value={v.split(' ').join('_').toLowerCase()}>{v}</MenuItem>
                   )}
                </Select>
            </Box> 
          </FormControl>
        </CardContent>
      </React.Fragment>
    )
}

export default function RegionActorSelector() {

  return (
    <CardContainer>  
      <Region/>
      <Actor/>
    </CardContainer>
  );
}