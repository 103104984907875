 import * as csv from "csvtojson";

const ROOT_URL = 'https://influence-v2-production.herokuapp.com/api/v1';


function authHeader(isForm=false){
    const user = JSON.parse(localStorage.getItem('currentUser'));
    if(user && user.auth_token){
      return  isForm ? {
          'Authorization': user.auth_token,
      } : 
      {
          'Authorization': user.auth_token,
          'Content-Type': 'application/json'
      }
    }else{
      return{};
    }

}

export  function uploadForm(payload){
    return fetch(`${ROOT_URL}/tickets`,
      {
        method: 'POST',
        headers: authHeader(true),
        body: payload,
      });
}


export function setRegion(dispatch, region){
    dispatch({ type: 'REGION_CHANGE', payload:region });
}
export function setActor(dispatch, actor){
    dispatch({ type: 'ACTOR_CHANGE', payload:actor });
}
export function setProject(dispatch, project){
    dispatch({ type: 'PROJECT_CHANGE', payload:project});
}

export async function fetchProjectData(dispatch){
 try{
        // setLoading(true);
        dispatch({ type: 'REQUEST_PROJECT_DATA' });
        //temp way of doin this until we have real data
         const res = await fetch('/projectData.json',{
          // headers:authHeader()
        });
        if(res.status === 401){
          //TOKEN IS EXPIRED/ LOGOUT
          dispatch({ type: 'PROJECT_DATA_ERROR', error: "Token Expired"});
          // dispatch({ type: 'LOGOUT' });
          localStorage.removeItem('currentUser');

        // console.log(res);
        }else if(res.status === 200){
          const dataRes = await res.json();
          dispatch({ type: 'PROJECT_DATA_SUCCESS', payload: dataRes });
          // return 
        }else{
          dispatch({ type: 'PROJECT_DATA_ERROR', error: "Project Not Found."});
        }
      }catch (error){
        dispatch({ type: 'PROJECT_DATA_ERROR', error: error});
        // throw error;
      }

}

export async function fetchMissionData(dispatch, currentRegion, currentActor, currentProject){
      try{
        // setLoading(true);
        dispatch({ type: 'REQUEST_MISSION_DATA' });
        //temp way of doin this until we have real data

        const res = await fetch(`/data/${currentRegion}.json`,{
          // headers:authHeader()
        });
        if(res.status === 401){
          //TOKEN IS EXPIRED/ LOGOUT
          dispatch({ type: 'MISSION_DATA_ERROR', error: "Token Expired"});
          localStorage.removeItem('currentUser');
        }else if(res.status === 200){
          const dataRes = await res.json();
          const csvData = await fetch(`/data/${currentRegion}.csv`);
          const csvString = await csvData.text();
          const jsonArray= await csv().fromString(csvString);
          dataRes.targetLength = jsonArray.length;
          dataRes.targets = jsonArray.slice(0,100);
          dispatch({ type: 'MISSION_DATA_SUCCESS', payload: dataRes });
          // return 
        }else{
          dispatch({ type: 'MISSION_DATA_ERROR', error: "Mission Data Not Found."});
        }
      }catch (error){
        dispatch({ type: 'MISSION_DATA_ERROR', error: error});
        // throw error;
      }
}



export async function loginUser(dispatch, loginPayload) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({user:loginPayload}),
    redirect: 'follow'
  };
 
  try {

    dispatch({ type: 'REQUEST_LOGIN' });
    let response = await fetch(`${ROOT_URL}/login`, requestOptions);
    let data = await response.json();
    if (data.data && data.data.attributes.email) {
      let token = response.headers.get('Authorization');
      let newPayload = {
        user:data.data.attributes.email,
        auth_token: token ? token : ''
      }
      dispatch({ type: 'LOGIN_SUCCESS', payload: newPayload });
      localStorage.setItem('currentUser', JSON.stringify(newPayload));
      return newPayload
    }
    dispatch({ type: 'LOGIN_ERROR', error: "User not found."});
    return;
  } catch (error) {
    dispatch({ type: 'LOGIN_ERROR', error: error });
  }
}
 
export async function logout(dispatch) {
  try {
    let response = await fetch(`${ROOT_URL}/logout`, {
      method: 'DELETE',
      headers: authHeader()
    });
    console.log("LOGOUT: ", response);
   } catch (error) {
    console.log(error);
    // dispatch({ type: 'LOGIN_ERROR', error: error });
  }
    dispatch({ type: 'LOGOUT' });

  localStorage.removeItem('currentUser');
  // localStorage.removeItem('token');
}