import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';

import theme from '../theme.js';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { Fade } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';

import TopToolbar from '../Components/TopToolbar';
// import MissionStats from '../Components/MissionStats';
// import MissionDetail from '../Components/MissionDetail';
import Mission from '../Components/Mission';
import Campaign from '../Components/Campaign';
import MissionDetail from '../Components/Mission/MissionDetail';

import Drawer from '../Components/Drawer'
import {  useAuthDispatch, 
          logout, 
          fetchMissionData, 
          fetchProjectData,
          useAuthState
        } from '../Context';

import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root:{},
  container: {
    position:'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: 0,
    paddingLeft:theme.spacing(8),
    paddingRight:theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
      paddingRight:theme.spacing(1),
      paddingLeft:theme.spacing(1)
    }
  },
  mission_container:{
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

  },
    background:{
     background: 'url(/background.png) no-repeat center center fixed',
     backgroundSize: 'cover'
  },

  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',

    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',

    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  loading:{
  
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    "& > div":{
        color: 'white',
    },
     opacity:1,
         // transition: "opacity .5s",
             // transitionDelay: '1s'


  },
  fade:{
    opacity:0,
    // transition: "opacity .5s",
    // transitionDelay: '1s'
  }
}));

export default function Dashboard(props) {
  // const { loading, data } = useFetch("/data.json");
  const classes = useStyles(theme);
  const dispatch = useAuthDispatch() // read dispatch method from context
  const { projectData,
          projectLoading,
          projectErrorMessage,
          missionData, 
          missionLoading, 
          missionErrorMessage, 
          currentRegion, 
          currentActor, 
          currentProject} = useAuthState(); //read user details from context  
  // const [loading, setLoading] = useState(true);

  const missionRef = useRef();
  const [currentTarget, setCurrentTarget] = useState(null);
  
  // const [projectType, setProjectType] = useState(null);

  useEffect(()=>{
      document.title = 'Dashboard';
  },[]);

  useEffect(()=>{
    // console.log("LOADING? ", dataLoading);
  },[projectLoading]);



  useEffect(()=>{
    async function fetchProject() {
     // if(currentProject.length){
        let response = await fetchProjectData(dispatch);
     // }
    }
   fetchProject()
  },[]);


  useEffect(()=>{

    async function fetchMission() {
        let response = await fetchMissionData(dispatch, currentRegion, currentActor)
    }
    if(currentRegion.length){
      console.log(currentRegion);
      fetchMission();
    }
  },[currentRegion])


  // useEffect(()=>{
  //   console.log("MISSION DATA", missionData);
  // },[missionData]);

  useEffect(()=>{
    if(missionErrorMessage && missionErrorMessage.error == "Token Expired"){
      console.log("DATA ERROR MESSAGE: ", missionErrorMessage)
      handleLogout();
    } 
  },[missionErrorMessage]);

  useEffect(()=>{
    if(projectErrorMessage && projectErrorMessage.error == "Token Expired"){
      console.log("DATA ERROR MESSAGE: ", projectErrorMessage)
      handleLogout();
    } 
  },[projectErrorMessage]);

  const handleLogout = () => {
    logout(dispatch);
    props.history.push('/login');
  };

  const handleSelectTarget = (v)=>{
    setCurrentTarget(v);
  }
  // const handleOpenDetail = ()=>{
  //   if(missionRef.current)missionRef.current.openDetail();
  // }

    const [detailOpen, setDetailOpen] = useState(false);

  const handleCloseDetail = ()=>{
     setDetailOpen(false);
  }
  const handleOpenDetail = ()=>{
     setDetailOpen(true);
  }
  return(
    <React.Fragment>
      <Box className={classes.loading}>
        <Fade in={ missionLoading || projectLoading } timeout={{ enter:0, exit: 500 }}>
          <CircularProgress/>
        </Fade>
      </Box>
      <Drawer />
      <main className={clsx(classes.content,classes.background)}>
        <Container maxWidth="xl" className={classes.mission_container}>
          {<TopToolbar 
            handleTargetSelect={handleSelectTarget} 
            selectedTarget={currentTarget} 
            handleDetailOpen={handleOpenDetail} 
            handleLogout={handleLogout} 
            />}
        </Container>
       { missionData && <Container maxWidth="xl" className={classes.container}>
            {missionData.type == "mission" ? <Mission ref={missionRef} 
                    handleTargetSelect={handleSelectTarget} 
                    currentTarget={currentTarget} 
                    handleLogout={handleLogout} detailOpen={detailOpen} />
                    :  <Campaign active={!detailOpen} />}

              {!missionLoading && detailOpen && missionData.mission && <MissionDetail data={missionData.mission} handleClose={handleCloseDetail}/>}
         
        </Container> }

      </main>
    </React.Fragment>
  )
}