import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import theme from '../theme.js';



const useStyles = makeStyles((theme) => ({
  root: {
    borderStyle: 'solid',
    borderWidth: '1px',
    boxShadow:'none',
    padding: theme.spacing(3, 3, 3, 3),


    backgroundColor:'transparent',
    // special characters in data URIs have to be encoded, see the compiled CSS in the codepen below
    // (also do not wrap the lines, that's just for better uderstanding)
    borderImageSource: 'url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 30 30%22%3E%3Crect x=%220.5%22 y=%220.5%22 width=%2229%22 height=%2229%22 fill=%22transparent%22 stroke=%22%23575865%22 stroke-width=%221%22 %2F%3E%3C%2Fsvg%3E")',
    borderImageSlice: '50%', // slice the image to 4 parts through the middle
    borderImageWidth: '15px', // width/height of the corners (or 40px)
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
}));


export default function CardContainer({children}) {
  const classes = useStyles(theme);

  return(
    <Card className={classes.root}>
      {children}
    </Card>
    );
};
