import React, { useRef, useEffect,useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { format } from "date-fns";

import Typography from '@material-ui/core/Typography';

import theme from '../theme.js';



const useStyles = makeStyles((theme) => ({
  root: {
    // width/height of the corners (or 40px)
  },
   paper: {
    padding: theme.spacing(2,0),
    display: 'grid',
    overflow: 'auto',
    // opacity:.25,
    transition:'opacity .25s',
    // borderStyle: 'solid',
    // borderWidth: '1px',
    boxShadow:'none',
    cursor: 'pointer',
    // special characters in data URIs have to be encoded, see the compiled CSS in the codepen below
    // (also do not wrap the lines, that's just for better uderstanding)
    // borderImageSource: 'url("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%22http:%2F%2Fwww.w3.org%2F2000%2Fsvg%22 viewBox=%220 0 30 30%22%3E%3Crect x=%220.5%22 y=%220.5%22 width=%2229%22 height=%2229%22 fill=%22transparent%22 stroke=%22%23575865%22 stroke-width=%221%22 %2F%3E%3C%2Fsvg%3E")',
    // borderImageSlice: '50%', // slice the image to 4 parts through the middle
    // borderImageWidth: '15px', // width/height of the corners (or 40px)

    // '&:hover':{
    //     opacity:.5,
    //     transition:'opacity .25s',
    // }
  },

   fixedHeight: {
    // minHeight: '240px',
    // [theme.breakpoints.down('sm')]: {
    //    minHeight: '140px',
    // },
    //  [theme.breakpoints.down('lg')]: {
    //    minHeight: '240px',
    // },
  },
  img:{
    width:'100%',
     [theme.breakpoints.down('md')]: {
      // maxWidth:'100px'
       // width: '75px',
       // display:'none'
    },

  },
  active:{
    opacity:1.0,
    transition:'opacity .25s',
    '&:hover':{
        opacity:1.0
    }
  },
  type:{
    paddingTop:'.125rem',
     [theme.breakpoints.down('lg')]: {
        fontSize:'14px'
     }
  },
  empty:{
    fontWeight:700
  },
  card:{
    padding: theme.spacing(3),
    background: '#595A6B',
    borderRadius:'0px',
    height:'100%',
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)'
    // filter: 'dropShadow(0px 8px 18px rgba(0, 0, 0, 0.12))'
  },
  cardActive:{
    boxShadow: '0px 8px 18px rgba(0, 0, 0, 0.12)'
  }
}));
function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

    function dataLine (data,style,variant="body2"){
      return data && <Typography className={style} noWrap variant={variant}>{data}</Typography>
    }


export default function MissionTargetCard({ target}) {
    const classes = useStyles(theme);
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    let formatted_date = isValidDate(new Date(target.Date)) ? format(new Date(target.Date),"MM/dd/yy Hmma") : null;


    return(
    <Box p={1.5}>
      <Box className={clsx(fixedHeightPaper, classes.active)}> 
        <Paper className={clsx(classes.card, classes.cardActive)} >
          <Grid container spacing={2} >
            <Grid xs={3} md={4} item>
                <img className={classes.img}  src={target.profile ? target.profile : 'default.jpeg'}/>
            </Grid>
            <Grid  xs={9} md={8} className={classes.type}  item>
              {dataLine(target['Full Name'],classes.empty,"body1")}
              {dataLine(`Response: ${target['Response']}`,classes.type)}
              {dataLine(target['City'],classes.type)}
              {dataLine(formatted_date,classes.type)}
            </Grid>
          </Grid>
        </Paper>
       </Box>
     </Box>
    );
};