import React from 'react';


export default function NotFound() {
  return(
    <React.Fragment>
      <div> Not Found</div>
    </React.Fragment>
  );
};
