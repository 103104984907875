// theme.js
import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          // WebkitFontSmoothing: 'auto',
           fontFamily: 'DM Sans',
        },
      },
    },
  },
  palette: {
    type: 'dark',

    background: {
      paper: '#32333D',
      default: '#32333D',
    },
    text:{
	    secondary: '#898AA1'
	},
  },
  typography: {
    fontFamily: "DM Sans"
	}
})
export default theme;
