import React, { useReducer } from "react";
 
let user = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser")).user
  : "";
let token = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser")).auth_token
  : "";
 
 let missionData = {};

 console.log(user);
 console.log(Boolean(token));

export const initialState = {
  userDetails: "" || user,
  token: "" || token,
  loading: false,
  errorMessage: null,
  missionData:null,
  missionLoading:false,
  missionErrorMessage:null,
  projectData:null,
  projectLoading:false,
  projectErrorMessage:null,
  currentProject:'',
  currentRegion:'',
  currentActor:'',
 
};


export const AuthReducer = (initialState, action) => {
  let data;
  switch (action.type) {
    case("PROJECT_CHANGE"):
      data = (initialState.currentMission === action.payload) ? {
           ...initialState
        }:{
           ...initialState,
          currentProject: action.payload
        };
        return data;
    case("ACTOR_CHANGE"):
      data = (initialState.currentActor === action.payload) ? {
           ...initialState
        }:{
           ...initialState,
          currentActor: action.payload
        };
        return data;
    case("REGION_CHANGE"):
      data = (initialState.currentRegion === action.payload) ? {
           ...initialState
        }:{
           ...initialState,
          currentRegion: action.payload
        };
        return data;
    case("REQUEST_MISSION_DATA"):
      return{
         ...initialState,
        missionLoading: true
      };
    case("MISSION_DATA_SUCCESS"):
      return{
         ...initialState,
        missionData: action.payload,
        missionLoading: false
      }
    case "MISSION_DATA_ERROR":
      return {
        ...initialState,
        missionLoading: false,
        missionErrorMessage: action.error
      };
    case("REQUEST_PROJECT_DATA"):
      return{
         ...initialState,
        projectLoading: true
      };
    case("PROJECT_DATA_SUCCESS"):
      return{
         ...initialState,
        projectData: action.payload,
        projectLoading: false
      }
    case "PROJECT_DATA_ERROR":
      return {
        ...initialState,
        projectLoading: false,
        projectErrorMessage: action.error
      };
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.auth_token,
        loading: false
      };
    case "LOGOUT":
      return {
        ...initialState,
        user: "",
        token: ""
      };
    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };
 
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};