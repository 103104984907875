// Context/context.js
import React, { useReducer } from 'react';
import { initialState, AuthReducer } from './reducer';

 
const AuthStateContext = React.createContext();
const AuthDispatchContext = React.createContext();

// const MissionDataContext = React.createContext();

// export function useMissionData(){
//   const context = React.useContext(MissionDataContext);
//   if (context === null) {
//     throw new Error('useMissionData must be used within a DataProvider tag');
//   }
//   return contextState;
// };


export function useAuthState() {
  const context = React.useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error("useAuthState must be used within a AuthProvider");
  }
 
  return context;
}
 
export function useAuthDispatch() {
  const context = React.useContext(AuthDispatchContext);
  if (context === undefined) {
    throw new Error("useAuthDispatch must be used within a AuthProvider");
  }
 
  return context;
}

// export const AuthProvider = ({ children }) => {
//   const [user, dispatch] = useReducer(AuthReducer, initialState);
 	
//   return (
//     <AuthStateContext.Provider value={user}>
//       <AuthDispatchContext.Provider value={dispatch}>
//         {children}
//       </AuthDispatchContext.Provider>
//     </AuthStateContext.Provider>
//   );
// };

// export const MissionDataProvide = ({children}) =>{
// 	  const [user, dispatch] = useReducer(AuthReducer, initialState);
// 	    return (
// 	<MissionDataContext.Provider value={}>
// 		{children}
//     </MissionDataContext.Provider>
//        );
// }

export const AuthProvider = ({ children }) => {
  const [user, dispatch] = useReducer(AuthReducer, initialState);
 	
  return (
    <AuthStateContext.Provider value={user}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

