import React, { useRef, useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

const OverflowTooltip = props => {
  // Create Ref
  const textElementRef = useRef();

  const compareSize = () => {
    const compare =
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);
    return () => { window.removeEventListener('resize', compareSize); }
  }, []);

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);


    return (
    <Tooltip
      title={props.title}
      interactive
      disableHoverListener={!hoverStatus}
      // style={{fontSize: '2em'}}
    >
     <Typography
        ref={textElementRef}
        className={props.style}
        noWrap
        variant={props.variant}
      >
        {props.title}
      </Typography>
    </Tooltip>
  );
};

export default OverflowTooltip;