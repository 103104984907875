import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { spacing } from '@material-ui/system';

// import { FixedSizeList } from 'react-window';
import theme from '../../theme.js';
import CardContainer from '../CardContainer';


const useStyles = makeStyles((theme) => ({
	root:{
		backgroundColor: '#292A31',
		display: 'block',
		textAlign: 'center',
		height: 'inherit',
		position:'relative',
	},
	image:{
		height: '65%',
		// height:'auto',
		// maxHeight:'200px',
		// width:'auto',
		backgroundSize: 'contain',
		backgroundPosition:'center',
		backgroundRepeat:"no-repeat"
	},
	box:{
		height:"inherit",

	},
	title:{
		fontSize:'21px'
	},
	active:{
		opacity:1
	},
	disabled:{
		opacity:.5
	}
}));

export default function Loading({icon, message, status=false}) {
	let classes = useStyles(theme);
	return(
		<Box p={6} mt={3} 
					className={clsx(classes.root, status ? classes.active : classes.disabled ) }>
		 	<Box display="flex"
		 			flexDirection="column"
		 			py={3}
		 			my={4}
        className={classes.box}>
				<Box xs={6} display="block" m={3} p={3} className={classes.image } style={{backgroundImage:`url(${icon})`}} flex={1}>
				</Box>
				<Box xs={6} flex={1} py={4} >
					<Typography className={classes.title} color="white">
						{message}
					</Typography>
				</Box>
		 	</Box>
		</Box>	
	);
}